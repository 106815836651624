<template>
    <div>
        <div class="dF jSB">
            <h4 class="pb-2" >{{currentUnitCategory}}</h4>
            <MoreActions v-if="selectedRowKeys.length > 1" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>
        </div>
        <div class="dF" style="justify-content:space-between; padding:18px 0">
            <div>
                <div>
                    {{cardName}}
                        <small class="ml-3" style="color:#9EA0A5">{{totalFloors}} in total</small>
                </div>
            </div>
            <div class="dF aC">
                <div>
                    Floor levels
                </div>
                <div style="color:var(--orange)">
                <a-icon style="color:var(--orange)" class="ml-3 mr-3" type="left" @click="prevFloor"/>

                <a-input readOnly @change="changeFloor" v-model="currentFloor" class="mr-3" min="1" style="color:var(--orange); width:40px" />/
                {{totalFloors}}
                <a-icon style="color:var(--orange)" class="ml-3" type="right" @click="nextFloor" />

                </div>
            </div>
        </div>
        <div v-if="!units.length">
            <EmptyApp :img="require(`@/assets/condo-logo.png`)" :text="'You don\'t have any Units on this floor'" />
        </div>
        <div v-else>
            <a-row :gutter="16">
                <div :class="openNav ? 'card-cols-with-nav':'model-card-cols'">
                    <div @click="selectUnit(unit.id)" class="mb-3" v-for="(unit, unitI) in units" :key="unitI">
                        <div :card-unit-value="unit.id" class="unit-holder">
                            <div class="display-unit">
                                <div class="relative">
                                    <div class="option-buttons absolute" style="width:100%; height:100%; top:0; left:0">
                                        <a-button-group>
                                            <a-button @click="editUnit(unit.id)" class="edit-button"><i class="edit-button-icon fe fe-edit-2" /></a-button>
                                            <a-button @click="previewUnit(unit.id)" class="edit-button"><i class="edit-button-icon fe fe-eye" /></a-button>
                                            <a-button @click="duplicateUnit(unit.id)" class="edit-button"><i class="edit-button-icon fe fe-copy" /></a-button>
                                            <a-button style="color:#FD647C" @click="deleteUnit(unit.id)" class="edit-button"><i class="edit-button-icon fe fe-trash" /></a-button>
                                        </a-button-group>
                                    </div>
                                    <div class="absolute" style="width:100%; top:0; left:0; z-index:200">
                                        <div class="dF" style="justify-content:space-between">
                                            <div class="px-3" :style="`background-color:${getColor(unit.salesStatus)}`" style="color:white; display:inline-block; border-radius:4px">{{getStatusName(unit.salesStatus)}}</div>
                                            <div><a-checkbox :checked="selectedRowKeys.includes(unit.id)" /></div>
                                        </div>
                                    </div>
                                    <img style="width:100%; height:220px; object-fit:cover" :src="unit.image" />
                                </div>
                                <div class="mt-3 dF" style="justify-content:space-between">
                                    <div>
                                        {{`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`}}
                                    </div>
                                    <div style="color:#A3A5AD">
                                        {{`$ ${unit.packages && unit.packages[0] && unit.packages[0].price && unit.packages[0].price.toLocaleString("US") || 0}`}}
                                    </div>
                                </div>
                                <hr>
                                <div class="dF" style="width:100%; justify-content:space-between" v-if="unit.packages && unit.packages[0]">
                                    <div style="font-size:12px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
                                        {{unit.packages[0].beds ? `${unit.packages[0].beds} Bed`:'0 Bed'}}
                                    </div><div style="font-size:12px">|</div>
                                    <div style="font-size:12px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
										{{unit.packages[0].baths ? `${unit.packages[0].baths} Bath`:'0 Bath'}}
                                    </div><div v-if="unit.packages[0].parking && unit.packages[0].parking != 0" style="font-size:12px">|</div>
                                    <div v-if="unit.packages[0].parking && unit.packages[0].parking != 0" style="font-size:12px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
                                        {{unit.packages[0].parking ? `${unit.packages[0].parking} Parking Spot` : '0 Parking Spot'}}
                                    </div><div v-if="unit.packages[0].locker && unit.packages[0].locker != 0" style="font-size:12px">|</div>
                                    <div v-if="unit.packages[0].locker && unit.packages[0].locker != 0" style="font-size:12px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
                                        {{unit.packages[0].locker ? `${unit.packages[0].locker} Locker` : '0 Locker'}}
                                    </div><div style="font-size:12px">|</div>
                                    <div style="font-size:12px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
                                        {{getExposure(unit.packages[0].exposure)}}
                                    </div><div style="font-size:12px">|</div>
                                    <div style="font-size:12px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
										{{unit.packages[0].sqft ? `${unit.packages[0].sqft} Sq.Ft`: '0 Sq.Ft'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-row>
            <div class="mr-3 dF aC" style="justify-content:flex-end; padding:10px">
                <div>
                    Floor levels
                </div>
                <div style="color:var(--orange)">
                    <a-icon class="ml-3 mr-3" type="left" @click="prevFloor"/>
                    <a-input readOnly @change="changeFloor" v-model="currentFloor" class="mr-3" min="1" style="color:var(--orange); width:40px" />/
                    {{totalFloors}}
                    <a-icon class="ml-3" type="right" @click="nextFloor" />
                </div>
            </div>
        </div>
        <a-modal :visible="visible" :title="null" :footer="null" @cancel="closeModal" centered>
            <h5>{{title}}</h5>
            <p>{{message}}</p>
            <a-select v-if="title === 'Change Sales Status'" v-model="newSalesStatus" style="width:100%">
                <a-select-option v-for="stat in salesStatus" :key="stat.value">{{stat.label}}</a-select-option>
            </a-select>
            <a-select v-else v-model="newPublishStatus" style="width:100%">
                <a-select-option v-for="stat in status" :key="stat.value">{{stat.label}}</a-select-option>
            </a-select>
            <div style="text-align:end">
                <a-button style="width:120px" type="primary" class="mt-5 mr-3" @click="confirm">CONFIRM</a-button>
                <a-button style="width:120px" class="mt-5" @click="visible =! visible">CANCEL</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import MoreActions from 'bh-mod/components/common/MoreActions'
export default {
    props:{
        units:{
            type:Array,
            default:() => []
        },
    },
    components:{
        MoreActions,EmptyApp
    },
    computed:{
        openNav() {
            return this.$store.state.condoUnits.openNav
        },
        currentUnitCategory() {
            let unitCategory = this.$store.state.condoUnits.currentUnitCategory
            if (unitCategory == 'all') unitCategory= 'Floors and Units'
            return unitCategory
        },
        cardName() {
            return this.$store.getters.cardName
        },
        totalFloors() {
            return this.$store.state.condoUnits.floors.length
        },
        currentFloor() {
            let current = this.$store.state.condoUnits.currentFloor
            return current
        },
        instance(){
          return this.$store.state.instance
        },
        storeUnits() {
            return this.$store.state.condoUnits.units
        },
    },
    data(){
        return {
            selectedRowKeys:[],
            visible:false,
            newSalesStatus:'',
            newPublishStatus:'',
            status:[
                {label:'Published', value:'published'},
                {label:'Draft', value:'draft'}
            ],
            salesStatus:[
                {label:'Available', value:'available'},
                {label:'Unavailable', value:'unavailable'},
                {label:'Hold', value:'hold'},
                {label:'Sold', value:'sold'}
            ],
            title:'',
            message:'',
            bulkActions:[
                // {
                //     label:'Change Sales Status',
                //     value:'changeSalesStatus'
                // },
                {
                    label:'Change Publish Status',
                    value:'changePublishStatus'
                },
                {
                    label:'Delete Selected Units',
                    value:'delete'
                }
            ],
        }
    },
    methods:{
        duplicateUnit(id) {
            this.$emit('duplicateUnit',id)
        },
        confirm() {
            if (this.title == 'Change Sales Status') return this.changeSalesStatus()
            else if (this.title == 'Change Publish Status') return this.changePublishStatus()
        },
        changePublishStatus() {
            let units = Object.values(this.storeUnits)
            this.selectedRowKeys.forEach(id => {
                units.forEach(unit => {
                    if (id == unit.id) {
                        unit.status = this.newPublishStatus
                        let sendObj =JSON.parse(JSON.stringify(unit))
                        this.$api.put(`/units/${this.instance.id}/${unit.id}?type=condounits`, sendObj).then( ({data}) => {
                            console.log('just updated')
                            this.newPublishStatus = ''
                            this.visible = false
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            })
        },
        changeSalesStatus() {

            let units = Object.values(this.storeUnits)
            this.selectedRowKeys.forEach(id => {
                units.forEach(unit => {
                    if (id == unit.id) {
                        unit.salesStatus = this.newSalesStatus
                        let sendObj =JSON.parse(JSON.stringify(unit))
                        this.$api.put(`/units/${this.instance.id}/${unit.id}?type=condounits`, sendObj).then( ({data}) => {
                            console.log('just updated')
                            this.newSalesStatus = ''
                            this.visible = false
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            })

        },
        closeModal(){
            this.visible = false
            this.newSalesStatus = ''
            this.newPublishStatus = ''
        },
        editUnit(id) {
            // let crumbs = JSON.parse(JSON.stringify(this.$store.state.crumbs))
            // crumbs.push({name:`Unit ${this.$store.state.condoUnits.units[id].unitNumber} - ${this.$store.state.condoUnits.units[id].name}`})
            // this.$store.commit('SET_CRUMBS', crumbs)
            this.$router.push(`/unitdetails/${id}`)
        },
        previewUnit(image) {
            if (image) {
                this.$emit('previewImage', image)
            }
        },
        deleteUnit(id) {
            let self = this
            if (this.$p < 40) return this.$message.error('You do not have permission to delete units')
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this unit?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$emit('deleteUnit', id)
                    let index = self.selectedRowKeys.findIndex (x => x == id)
                    if (index != -1) {
                        self.selectedRowKeys.splice(index,1)
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        selectUnit(id) {
            if (this.selectedRowKeys.includes(id)) {
                let index = this.selectedRowKeys.findIndex(x => x == id)
                this.selectedRowKeys.splice(index,1)
            } else {
                this.selectedRowKeys.push(id)
            }
        },
        getColor(status) {
            let color = ''
			switch(status) {
				case 'available': color = '#1EC48C'
				break;
				case 'sold': color = '#FD6C83'
				break;
				case 'hold': color = '#FECE5B'
				break;
				case 'conditional' : color = '#9693E8'
				break;
				case 'soldConditional': color = '#9693E8'
				break;
				case 'inventory': color = '#3395c6'
				break;
				case 'notreleased': color = '#3F3356'
				break;
				case 'allocated': color = 'var(--primary)'
				break;
				case 'approved_for_reservation': color = '#134F5C'
				break;
				case 'reserved': color = '#419E00'
				break;
			}
			return color
        },
		getStatusName(status){
			let name = ''
			switch(status) {
				case 'available': name = 'AVAILABLE'
				break;
				case 'sold': name = 'SOLD'
				break;
				case 'hold': name = 'HOLD'
				break;
				case 'conditional': name = 'CONDITIONAL'
				break;
				case 'soldConditional': name = 'CONDITIONAL'
				break;
				case 'inventory': name = 'INVENTORY'
				break;
				case 'notreleased': name = 'NOT RELEASED'
				break;
				case 'allocated': name = 'ALLOCATED'
				break;
				case 'approved_for_reservation': name = 'APPROVE FOR RESERVATION'
				break;
				case 'reserved': name = 'RESERVED'
				break;
			}
			return name
		},
        getExposure(item) {
			let exposures = {
				'none': 'N/A',
				'north': 'N',
				'south': 'S',
				'east': 'E',
				'west': 'W',
				'northEast': 'NE',
				'southEast': 'SE',
				'southWest': 'SW',
				'northWest': 'NW',
				'northEastSouth': 'NES',
				'eastSouthWest': 'ESW',
				'southWestNorth': 'SWN',
				'westNorthEast': 'WNE',
				'all': 'All',
			}
            let exposure = ''
            if (!item || item == 'none') {
                exposure = 'N/A'
            } else {
                exposure = exposures[item]
            }
            return exposure
        },
        async bulkActioned({key,keyPath,val}){
            let self = this
            console.log('KEY',key)

            if (key == 'delete') {
                if (this.$p < 40) return this.$message.error('You do not have permission to delete units')
                this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this unit?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    for (var i=0; i<self.selectedRowKeys.length; i++) {
                        let id = self.selectedRowKeys[i]
                        self.$api.delete(`/units/${self.instance.id}/${self.selectedRowKeys[i]}`, {buildingType:'condounits'}).then( ({data}) => {

                        self.$store.commit('SET_PROP', {where:['units',id],del:true})
                        }).catch( err => {
                            console.error('errror', err)
                        })
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
                })
            }
            if (key == 'changeSalesStatus') {
                this.visible = true
                this.title = 'Change Sales Status'
                this.message = 'Select a new Sales Status for your units'
            }
            if (key == 'changePublishStatus') {
                if (this.$p < 40) return this.$message.error('You do not have permission to change publish status')
                this.visible = true
                this.title = 'Change Publish Status'
                this.message = 'Select a new Publish Status for your units'
            }
        },
        nextFloor () {
            let currentFloor = this.$store.state.condoUnits.currentFloor
            if (currentFloor == this.totalFloors) {
                return this.$message.error('You have reached the last floor')
            }
            else {
                currentFloor += 1
                this.$store.commit('CHANGE_FLOOR', currentFloor)
            }
        },
        prevFloor () {
            let currentFloor = this.$store.state.condoUnits.currentFloor
            if (currentFloor == 1) {
                return this.$message.error('You have reached the last floor')
            }
            else {
                currentFloor -= 1
                this.$store.commit('CHANGE_FLOOR', currentFloor)
            }
        },
        changeFloor(data) {
            console.log('data', data.data)
        },
    }
}
</script>

<style scoped lang="scss">
.unit-holder:not(:hover){
   .option-buttons {
       display:none;
   }
}
.unit-holder:hover{
    .option-buttons {
        display:flex;
    }
}
.option-buttons{
    background-color:rgba(0, 0, 0, 0.3);
    align-items: center;
    justify-content: center;
    z-index: 100;
}
</style>

<style lang="scss" scoped>
    .edit-button:hover {
        .edit-button-icon {
            color: var(--orange);
        }
    }
    .edit-button {
        z-index:300;
    }
</style>

<style scoped>
.display-unit:hover{
    background-color:var(--off-white-dark);
}
.display-unit{
    background-color: #FFF;
    padding:24px;
    cursor:pointer;
}
.model-card-cols{
    display:grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap:20px;
}
@media screen and (min-width:900px){
    .model-card-cols{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (min-width:1150px){
    .model-card-cols{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (min-width:1480px){
    .model-card-cols{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
@media screen and (min-width:1800px){
    .model-card-cols{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}


.card-cols-with-nav{
    display:grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap:20px;
}
@media screen and (min-width:1100px){
    .card-cols-with-nav{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (min-width:1380px){
    .card-cols-with-nav{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (min-width:1650px){
    .card-cols-with-nav{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
@media screen and (min-width:1900px){
    .card-cols-with-nav{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}
</style>
